import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';
import { useContext } from 'react';

import { SocketContext } from '../providers/SocketProvider/SocketProvider';
import backendService from '../services/backendService';

function useLiveGameImage(
  tenantId: string,
  imageId?: string,
  modfiedAt?: number,
  isAuthenticated?: boolean,
) {
  const { gameAPI } = useContext(SocketContext);
  const { data, isFetching, error } = useQuery(
    [`image`, imageId, modfiedAt],
    async () => {
      if (!imageId) return Promise.resolve(undefined);
      let urlRes;
      if (isAuthenticated === false) {
        urlRes = (
          await backendService.instance.getPublicDownloadUrl(
            `${imageId}`,
            'game_responses',
          )
        ).data;
      } else {
        urlRes = gameAPI
          ? await gameAPI?.game.getDownloadUrl({
              pathname: `game_responses/${imageId}`,
              tenantId,
            })
          : (
              await backendService.instance.getPublicDownloadUrl(
                `${imageId}`,
                'game_responses',
              )
            ).data;
      }

      if (urlRes) {
        const res = await Axios.request<Blob>({
          method: 'get',
          url: urlRes.url,
          responseType: 'blob',
        });
        const objectUrl = URL.createObjectURL(res.data);
        return objectUrl;
      }
      return undefined;
    },
    {
      enabled: Boolean(imageId),
      staleTime: 30 * 60 * 1000,
      retry: 3,
    },
  );

  return {
    imageUrl: data,
    error,
    isLoading: isFetching,
  };
}

export default useLiveGameImage;
