import React, { createContext } from 'react';

import GameAPI from '../../frontendServices/gameAPI';

export const SocketContext = createContext<{ gameAPI?: GameAPI }>({
  gameAPI: undefined,
});

const SocketProvider = ({
  children,
  gameAPI,
}: {
  children: React.ReactNode;
  gameAPI: GameAPI;
}) => {
  return <SocketContext.Provider value={{ gameAPI }}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
