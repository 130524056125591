const CuriRocketBigFlameSvg = () => (
  <svg
    width="465"
    height="461"
    viewBox="0 0 465 461"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M192.729 214.962C192.729 214.962 147.639 230.7 112.065 254.214C76.4909 277.728 46.951 319.328 46.951 319.328C54.8872 316.3 62.9884 313.723 71.2155 311.61C82.1171 309.04 88.8052 310.807 88.8052 310.807C88.8052 310.807 52.9351 340.568 30.8917 376.806C8.84838 413.044 0.633545 455.759 0.633545 455.759C0.633545 455.759 34.0616 446.249 57.0998 433.396C80.138 420.543 101.195 398.606 101.195 398.606C100.217 409.243 98.3461 419.779 95.6028 430.102C92.5599 440.462 88.6728 450.554 83.9804 460.278C83.9804 460.278 150.95 436.721 191.937 389.194C232.925 341.667 251.736 273.974 251.736 273.974L192.729 214.962Z"
      fill="#FFA154"
    />
    <path
      d="M205.753 159.267C205.753 159.267 160.756 157.576 118.836 179.739C76.9155 201.903 38.0742 247.923 38.0742 247.923L89.6088 268.097C89.6088 268.097 102.458 266.767 125.159 260.432C147.86 254.098 184.937 267.702 184.937 267.702L205.753 159.267Z"
      fill="#C61F13"
    />
    <path
      d="M91.3943 257.284C68.2217 256.222 38.0742 247.923 38.0742 247.923L89.6951 267.977C89.6951 267.977 109.596 264.34 131.645 259.103C139.193 258.362 146.808 258.662 154.274 259.995L162.698 228.05C162.698 228.05 153.523 247.39 130.949 252.253C108.375 257.115 114.566 258.344 91.3943 257.284Z"
      fill="#7E2C26"
    />
    <path
      d="M238.605 301.27L250.422 300.895C250.422 300.895 225.482 354.762 184.645 397.853C143.809 440.945 84.6412 460.171 84.6412 460.171C84.6412 460.171 157.34 421.063 189.703 377.573C222.067 334.084 238.605 301.27 238.605 301.27Z"
      fill="#D84C42"
    />
    <path
      d="M313.18 266.694C313.18 266.694 314.871 311.691 292.707 353.611C270.544 395.531 224.524 434.373 224.524 434.373L204.35 382.838C204.35 382.838 205.68 369.989 212.015 347.288C218.349 324.587 204.745 287.51 204.745 287.51L313.18 266.694Z"
      fill="#C61F13"
    />
    <path
      d="M164.142 266.719C164.142 266.719 155.044 265.196 133.073 274.443C111.102 283.689 80.6004 299.451 80.6004 299.451C80.6004 299.451 92.6284 301.584 101.357 301.081C110.085 300.578 121.749 307.194 121.749 307.194C121.749 307.194 90.9992 328.377 69.0813 359.861C47.1634 391.346 23.9874 430.446 23.9874 430.446C23.9874 430.446 46.3621 412.733 63.6381 403.094C80.9141 393.455 129.169 354.148 129.169 354.148C129.169 354.148 119.906 389.385 118.967 400.585C118.028 411.786 108.834 432.727 108.834 432.727C108.834 432.727 133.411 408.005 164.142 372.373C194.872 336.74 202.471 304.005 202.471 304.005C202.471 304.005 194.174 295.014 180.6 281.44C167.026 267.866 164.142 266.719 164.142 266.719Z"
      fill="#FDDA5D"
    />
    <path
      d="M313.18 266.694C313.18 266.694 314.871 311.691 292.707 353.611C270.544 395.531 224.524 434.373 224.524 434.373L207.049 389.661C207.049 389.661 220.598 411.931 237.172 403.521C252.218 395.887 270.167 370.167 280.092 351.596C300.725 312.987 296.784 272.339 296.784 272.339L313.18 266.694Z"
      fill="#7E2C26"
    />
    <path
      d="M455.625 9.12615C478.076 31.5775 457.923 162.532 398.773 221.682C339.623 280.833 240.768 356.636 174.441 290.309C108.115 223.983 185.247 126.439 244.394 67.292C303.541 8.14468 433.173 -13.3259 455.625 9.12615Z"
      fill="#61B195"
    />
    <path
      d="M432.772 1.10889C432.772 1.10889 449.194 2.13633 456.033 9.5362C458.616 12.062 461.137 18.7152 461.899 21.4241C464.269 29.8754 465.849 43.6697 464.492 63.2056C461.545 105.667 448.136 166.953 403.995 216.359C359.677 263.64 320.924 287.578 297.762 299.018C293.123 301.309 245.995 326.36 209.124 312.65C192.539 306.283 179.018 297.251 168.938 284.992C153.042 265.674 149.403 242.971 149.403 242.971C149.403 242.971 171.804 285.536 208.071 299.015C242.872 311.95 271.978 296.518 297.764 284.989C323.551 273.46 349.769 249.827 369.078 233.292C395.591 210.589 424.793 178.016 443.365 120.478C458.203 74.5052 458.773 25.7756 452.996 14.8063C447.218 3.83692 432.772 1.10889 432.772 1.10889Z"
      fill="#3D725F"
    />
    <path
      d="M247.414 215.974C284.514 253.074 344.664 253.074 381.764 215.974C418.864 178.874 418.864 118.724 381.764 81.6237C344.664 44.5239 284.514 44.5239 247.414 81.6237C210.314 118.724 210.314 178.874 247.414 215.974Z"
      fill="#FFA154"
    />
    <path
      d="M374.043 95.2122C394.333 115.502 402.786 142.554 392.471 152.868C386.226 159.114 378.606 157.601 373.366 152.361C365.588 144.583 367.953 131.057 352.061 115.165C330.756 93.8595 302.182 97.0705 282.569 116.684C263.123 136.13 260.088 164.527 281.393 185.832C297.286 201.724 310.643 199.525 318.423 207.305C323.662 212.545 325.356 219.984 318.932 226.408C308.785 236.554 281.563 228.269 261.275 207.98C225.42 172.121 229.152 125.128 260.091 94.1883C291.353 62.9038 338.186 59.3555 374.043 95.2122Z"
      fill="white"
    />
    <path
      d="M296.687 151.266C298.727 153.306 301.326 154.695 304.156 155.258C306.985 155.821 309.918 155.532 312.584 154.428C315.249 153.324 317.527 151.454 319.13 149.056C320.733 146.657 321.588 143.836 321.588 140.951C321.588 138.066 320.733 135.246 319.13 132.847C317.527 130.448 315.249 128.579 312.584 127.475C309.918 126.371 306.985 126.082 304.156 126.645C301.326 127.208 298.727 128.597 296.687 130.637C293.951 133.372 292.414 137.083 292.414 140.951C292.414 144.82 293.951 148.53 296.687 151.266Z"
      fill="white"
    />
    <path
      d="M326.638 181.218C328.679 183.257 331.278 184.647 334.107 185.209C336.937 185.772 339.87 185.483 342.535 184.379C345.201 183.275 347.479 181.405 349.081 179.006C350.684 176.607 351.539 173.787 351.539 170.902C351.539 168.017 350.684 165.197 349.081 162.798C347.478 160.399 345.2 158.53 342.534 157.426C339.869 156.322 336.936 156.033 334.106 156.596C331.277 157.159 328.678 158.548 326.638 160.588C325.283 161.942 324.209 163.55 323.476 165.32C322.743 167.09 322.365 168.987 322.365 170.903C322.365 172.819 322.743 174.715 323.476 176.485C324.209 178.255 325.284 179.863 326.638 181.218Z"
      fill="white"
    />
    <path
      d="M85.0638 415.422C79.0091 420.428 72.691 425.106 66.1369 429.438C29.284 453.789 0.886486 455.415 0.886486 455.415C0.886486 455.415 38.6057 436.915 65.1146 420.027C91.6236 403.139 106.924 387.863 106.924 387.863C106.924 387.863 111.343 393.728 85.0638 415.422Z"
      fill="#D84C42"
    />
    <path
      d="M71.7512 311.486C61.6905 313.454 47.4023 319.424 47.4023 319.424C47.4023 319.424 60.3965 307.744 70.4633 305.776C75.5758 304.749 80.8612 304.982 85.864 306.452C89.9257 307.799 93.7971 309.663 97.3824 311.999C97.3824 311.999 81.8175 309.523 71.7512 311.486Z"
      fill="#D84C42"
    />
  </svg>
);
export default CuriRocketBigFlameSvg;
