export interface Props {
  color: string;
  size?: number;
}
const ChevronLeft = (props: Props) => {
  const { color, size = 16 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87583 8.00005L12.2023 12.862L11.1694 13.8048L4.81006 8.00005L11.1694 2.19531L12.2023 3.13812L6.87583 8.00005Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronLeft;
