import React from 'react';

import useLiveGameImage from '../../../hooks/useLiveGameImage';
import { Image, ObjectFit } from '../../_atoms/Image';

type Props = {
  imageId: string;
  onImageLoaded?: () => void;
  onImageClicked?: (img: string) => void;
  fluid?: boolean;
  style?: React.CSSProperties;
  objectFit?: ObjectFit;
  boxShadow?: string;
  tenantId: string;
  modifiedAt?: number;
  isAuthenticated: boolean;
};

const LiveGameImage = (props: Props) => {
  const {
    imageId,
    onImageClicked,
    fluid,
    onImageLoaded,
    style,
    objectFit,
    boxShadow,
    tenantId,
    modifiedAt,
    isAuthenticated,
  } = props;
  const { imageUrl } = useLiveGameImage(tenantId, imageId, modifiedAt, isAuthenticated);

  return (
    <Image
      style={style}
      fluid={fluid}
      src={imageUrl}
      objectFit={objectFit}
      boxShadow={boxShadow}
      onImageLoaded={onImageLoaded}
      onClick={() => onImageClicked && imageUrl && onImageClicked(imageUrl)}
    />
  );
};

export default LiveGameImage;
