import { Tooltip } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import DisabledSvg from '../../../assets/icons/DisabledSvg';
import ProfileChecked from '../../../assets/icons/ProfileChecked';
import UndoSvg from '../../../assets/icons/UndoSvg';
import theme from '../../../assets/theme';
import useImagePreview from '../../../hooks/useImagePreview';
import useRouterMatch from '../../../hooks/useRouterMatch';
import { GameMode } from '../../../pages/[tenantId]/games/[gameId]/curimote';
import { PartialParticipant, Response } from '../../../services/backendService/types';
import { ById } from '../../../services/database/types';
import { getIndexToColor } from '../../../utils/roundUtils';
import LiveGameImage from '../../_molecules/LiveGameImage';
import { parseSlateStringToText } from '../../SlideComponents/slateUtil';
import { PollOptions, SlideType } from '../../SlideComponents/slide.types';
import { Button } from '../Button';
import { IconWrapper } from '../IconWrapper';

const ListItem = ({
  response,
  participantsById,
  onDismiss,
  slideType,
  pollOptions,
  currentGameMode,
  requireRealNames,
}: {
  slideType: SlideType;
  response: Response;
  requireRealNames?: boolean;
  pollOptions?: PollOptions[] | null;
  participantsById: ById<PartialParticipant>;
  currentGameMode?: GameMode;
  isLoadingFeedback: boolean;
  onDismiss: (data: { responseId: string; dismiss: boolean }) => void;
}) => {
  const { t } = useTranslation('Host');
  const isDismissed = response.dismissed;
  const participant = participantsById[response.uid];
  const { imagePreviewComponent, onImageClicked } = useImagePreview(
    slideType === 'canvas' || slideType === 'aiAssessedImageAnswers' ? 'image' : 'video',
  );
  const getPollOptionFromResponseId = (responseId: string) => {
    return pollOptions?.find(o => o.id === responseId);
  };

  const roundOption = getPollOptionFromResponseId(response.response);

  const getPollOptionDisplayNameFromResponseId = (optionId: string) => {
    if (roundOption) return roundOption.displayName;
    return optionId;
  };
  const getRoundOptionIndex = (optionId?: string) => {
    if (!optionId) return;
    const index = pollOptions?.findIndex(o => o.id === optionId);
    return index;
  };

  return (
    <LeaderBoardContainerMobile $dismissed={isDismissed}>
      {imagePreviewComponent}
      <ResponseWrapper>
        <ResponseTextContainer $dismissed={isDismissed}>
          <HorizontalContainer>
            <PlaceMobile>
              <span>
                {/* Show real name */}
                {participant &&
                  requireRealNames &&
                  (participant.name || participant.nickName)}
                {/* Show nick name */}
                {participant && !requireRealNames && participant.nickName}
              </span>
            </PlaceMobile>
            {!isDismissed && slideType !== 'poll' && (
              <Button
                onClick={() => onDismiss({ responseId: response.id, dismiss: true })}
                icon={<DisabledSvg />}
                height="32px"
                styles={{ color: '#c44137', borderColor: '#c44137', padding: '0px 5px' }}
                type="tertiary"
              >
                {t('Dismiss')}
              </Button>
            )}
            {isDismissed && (
              <Button
                icon={<UndoSvg />}
                height="32px"
                type="tertiary"
                styles={{ color: theme.colors.black, borderColor: theme.colors.black }}
                onClick={() => onDismiss({ responseId: response.id, dismiss: false })}
              >
                {t('Undo')}
              </Button>
            )}
          </HorizontalContainer>
          {(slideType === 'canvas' || slideType === 'aiAssessedImageAnswers') && (
            <div style={{ position: 'relative', height: '100%' }}>
              <QuestionMobile $borderColor={'transparent'}>
                {' '}
                <DrawingImageGridItem
                  isAuthenticated={true}
                  response={response}
                  onClick={onImageClicked}
                />
              </QuestionMobile>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  margin: 0,
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  bottom: 20,
                  right: 0,
                }}
              >
                {response.isFinished && (
                  <Tooltip title={'Has submitted their drawing'}>
                    <IconWrapper
                      color={theme.colors.green}
                      height={'24px'}
                      width={'24px'}
                    >
                      <ProfileChecked />
                    </IconWrapper>
                  </Tooltip>
                )}
                {!response.isFinished && currentGameMode === 'inRound' && (
                  <span>
                    {t('Is drawing')}
                    <LoadingDots>...</LoadingDots>
                  </span>
                )}
              </div>
            </div>
          )}

          {slideType !== 'canvas' && slideType !== 'aiAssessedImageAnswers' && (
            <>
              {slideType === 'poll' && (
                <QuestionMobile
                  $borderColor={
                    roundOption?.color ||
                    getIndexToColor(getRoundOptionIndex(response.response))
                  }
                >
                  <span>
                    {parseSlateStringToText(
                      getPollOptionDisplayNameFromResponseId(response.response),
                    )}
                  </span>
                </QuestionMobile>
              )}
              {slideType !== 'poll' && (
                <QuestionMobile>
                  <span
                    style={{
                      fontSize: response.response.length <= 300 ? '24px' : '16px',
                    }}
                  >
                    {response.response}
                  </span>
                </QuestionMobile>
              )}
            </>
          )}
        </ResponseTextContainer>
      </ResponseWrapper>
    </LeaderBoardContainerMobile>
  );
};

const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const LeaderBoardItemContainer = styled.li<{
  $dismissed?: boolean;
}>`
  width: 100%;
  padding: 20px;
  padding-bottom: 5px;
  display: flex;
  background-color: ${theme.colors.white};
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  ${({ $dismissed }) =>
    $dismissed &&
    css`
      background-color: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      transform: scale(0.95);
      opacity: 0.5;
    `}
`;
const ResponseTextContainer = styled.div<{
  $dismissed?: boolean;
}>`
  ${({ $dismissed }) =>
    $dismissed &&
    css`
      background-color: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      transform: scale(0.95);
    `}
`;

const LeaderBoardContainerMobile = styled(LeaderBoardItemContainer)`
  flex-direction: column;

  & > * {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  height: 100%;
`;

const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const QuestionMobile = styled.div<{ $borderColor?: string }>`
  border-left: 5px solid
    ${({ $borderColor }) => ($borderColor ? $borderColor : theme.colors.orange)};
  padding-left: 5px;
  span {
    color: ${theme.colors.black};
    font-weight: bold;
    font-size: 24px;
  }
`;
const PlaceMobile = styled.div`
  span {
    color: ${theme.colors.text.grey};
    font-weight: bold;
    font-size: 20px;
  }
`;

const animationProps = {
  animate: {
    scale: [0, 1.05, 1],
  },
  transition: {
    duration: 0.6,
    type: 'spring',
  },
};
export const LoadingDots = styled.p`
  font-weight: bold;
  margin: 0;
  display: inline-block;
  clip-path: inset(0 1.2ch 0 0);
  animation: l 1s steps(4) infinite;

  @keyframes l {
    to {
      clip-path: inset(0 -0.3ch 0 0);
    }
  }
`;

export const DrawingImageGridItem = ({
  onClick,
  response,
  isAuthenticated,
}: {
  onClick?: (s: string) => void;
  response: Response;
  isAuthenticated: boolean;
}) => {
  const { tenantId } = useRouterMatch();
  if (!response.response) return null;
  return (
    <LiveGameImage
      tenantId={tenantId}
      modifiedAt={response.modifiedAt}
      imageId={response.response}
      style={{ width: '100%', background: 'white' }}
      objectFit="contain"
      boxShadow="none"
      fluid
      isAuthenticated={isAuthenticated}
      onImageClicked={s => onClick && onClick(s)}
    />
  );
};

const DismissableQuestionList = ({
  responses,
  participantsById,
  onDismiss,
  slideType,
  pollOptions,
  currentGameMode,
  requireRealNames,
}: {
  slideType: SlideType;
  pollOptions?: PollOptions[] | null;
  responses: Response[];
  requireRealNames?: boolean;
  currentGameMode?: GameMode;
  participantsById: ById<PartialParticipant>;
  onDismiss: (data: { responseId: string; dismiss: boolean }) => void;
}) => {
  return (
    <>
      <List>
        <AnimatePresence>
          {responses.map(q => (
            <motion.div key={q.id} {...animationProps}>
              <ListItem
                key={q.id}
                currentGameMode={currentGameMode}
                response={q}
                pollOptions={pollOptions}
                participantsById={participantsById}
                onDismiss={onDismiss}
                requireRealNames={requireRealNames}
                slideType={slideType}
                isLoadingFeedback={false}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </List>
    </>
  );
};
const List = styled.ul`
  display: grid;

  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 20px;
  justify-content: space-between;

  /* boring properties */
  list-style: none;

  padding: 1rem;
  width: 100%;
  margin: 0 auto;

  &::before {
    position: absolute;
    content: attr(data-keyword);
  }
`;

export default DismissableQuestionList;
