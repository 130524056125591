import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { useIsDesktop } from '../../../hooks/useIsDesktop';

type Props = {
  src?: string;
  rotation: number;
  type?: 'video' | 'image';
};
export const PreviewImage = (props: Props) => {
  const { type } = props;
  const isDesktop = useIsDesktop();
  const parentRef = useRef<HTMLDivElement>(null);
  const [dimentions, setDimensions] = useState<{ height: number; width: number }>({
    height: window.innerHeight * 0.9,
    width: window.innerWidth * 0.9,
  });

  // useEffect will run on stageCanvasRef value assignment
  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (parentRef.current) {
      const height = parentRef.current.offsetHeight;
      const width = parentRef.current.offsetWidth;
      if (height !== 0) {
        setDimensions({ height, width });
      }

      const imageIsOriginalOrientation =
        (!!props.rotation && props.rotation % 180 === 0) || props.rotation === 0;

      if (!imageIsOriginalOrientation) {
        const height = parentRef.current.offsetWidth;
        const width = parentRef.current.offsetHeight;
        setDimensions({ height, width });
      }
    }
  }, [parentRef, props.rotation, props.src]);

  return isDesktop ? (
    <PicContainer ref={parentRef} $height={dimentions.height} $width={dimentions.width}>
      {type && type === 'video' ? (
        <ReactPlayer controls url={props.src} width="100%" height="100%" />
      ) : (
        <FullSizeImage
          $height={dimentions.height}
          $width={dimentions.width}
          draggable={false}
          $rotation={props.rotation}
          src={props.src}
        />
      )}
    </PicContainer>
  ) : (
    <PicContainer ref={parentRef} $height={dimentions.height} $width={dimentions.width}>
      {type && type === 'video' ? (
        <ReactPlayer controls url={props.src} width="100%" height="100%" />
      ) : (
        <FullSizeImage
          $height={dimentions.height}
          $width={dimentions.width}
          draggable={false}
          $rotation={props.rotation}
          src={props.src}
        />
      )}
    </PicContainer>
  );
};

const PicContainer = styled.div<{ $height: number; $width: number }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullSizeImage = styled.img<{ $rotation: number; $height: number; $width: number }>`
  will-change: transform;
  display: block;
  max-width: ${({ $width }) => `${$width}`}px;
  max-height: ${({ $height }) => `${$height}`}px;
  border-radius: 25px;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: ${({ $rotation }) => `rotate(${$rotation}deg)`};
`;
