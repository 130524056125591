import { useEffect, useState } from 'react';

import theme from '../../../assets/theme';
import { LookupTenant } from '../../../services/backendService/types';
import { Input } from '../_Input';

const TenantTypeAhead = ({
  onTenantNameWritten,
  onPressEnter,
  label,
  placeholder,
}: {
  onSelected: (tenant: LookupTenant) => void;
  onTenantNameWritten: (tenantName: string) => void;
  onPressEnter: () => void;
  label?: string;
  placeholder?: string;
}) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    onTenantNameWritten(search);
  }, [onTenantNameWritten, search]);

  return (
    <Input
      value={search}
      placeholder={placeholder ? placeholder : 'e.g Lincoln high school'}
      onChange={e => setSearch(e.target.value)}
      onPressEnter={onPressEnter}
      label={label}
      labelStyle={{ fontSize: 14, color: theme.colors.black }}
    />
  );
};

export default TenantTypeAhead;
