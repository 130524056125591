import { Drawer, Modal } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import Hotkeys from 'react-hot-keys';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CrossSvg } from '../assets/icons';
import RotateSvg from '../assets/icons/RotateSvg';
import theme from '../assets/theme';
import { PreviewImage } from '../components/_organisms/PreviewImage';
import { useIsDesktop } from './useIsDesktop';

const Controls = ({ onRotateLeft }: { onRotateLeft: () => void }) => {
  const { t } = useTranslation('Host');

  const handleRPress = () => {
    onRotateLeft();
  };

  return (
    <ControlContainer>
      <Hotkeys keyName="r" onKeyDown={handleRPress} allowRepeat={true} />
      <IconButton onClick={onRotateLeft}>
        <RotateSvg />
        <span aria-label="press R to rotate" style={{ marginTop: 5, userSelect: 'none' }}>
          {t('Rotate (press R)')}
        </span>
      </IconButton>
    </ControlContainer>
  );
};

const IconButton = styled.div`
  padding: 8px 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  color: ${theme.colors.white};
  svg {
    color: ${theme.colors.white};
    height: 20px;
    width: 20px;
    transform: scale(-1, 1);
  }
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ControlContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
`;

const getNextRotation = (rotation: number) => {
  // Always rotate 90 degrees to the left because of the icon
  return rotation + 90;
};

function useImagePreview(type?: 'image' | 'video') {
  const { t } = useTranslation('Host');

  const isDesktop = useIsDesktop();
  const [previewImage, setPreviewImage] = useState<string>();

  const [rotation, setRotation] = useState(0);

  const handleCancel = () => {
    setPreviewImage(undefined);
  };

  const handleRotate = useCallback(
    () => setRotation(rotation => getNextRotation(rotation)),
    [],
  );

  const imagePreviewComponent = useMemo(
    () =>
      isDesktop ? (
        <ImagePreviewModal
          width="95%"
          visible={previewImage !== undefined}
          onOk={handleCancel}
          onCancel={handleCancel}
          footer={null}
          closable={false}
          destroyOnClose
          bodyStyle={{ padding: 0 }}
          centered
        >
          <FullSizeDiv>
            <ModalRow>
              <h1 style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 0 }}>
                {t('Image preview')}
              </h1>
              <IconWrapper onClick={handleCancel}>
                <CrossSvg />
              </IconWrapper>
            </ModalRow>
            <ImageContainer>
              {previewImage && (
                <PreviewImage type={type} rotation={rotation} src={previewImage} />
              )}
              {type !== 'video' && <Controls onRotateLeft={handleRotate} />}
            </ImageContainer>
          </FullSizeDiv>
        </ImagePreviewModal>
      ) : (
        <BottomDrawer
          width="50%"
          style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
          placement="bottom"
          height="95%"
          closable={false}
          onClose={handleCancel}
          destroyOnClose
          visible={previewImage !== undefined}
          drawerStyle={{
            backgroundColor: '#ffffff',
          }}
        >
          <FullSizeDiv>
            <ModalRow>
              <h1 style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 0 }}>
                {t('Image preview')}
              </h1>
              <IconWrapper onClick={handleCancel}>
                <CrossSvg />
              </IconWrapper>
            </ModalRow>
            <ImageContainer>
              {previewImage && (
                <PreviewImage type={type} rotation={rotation} src={previewImage} />
              )}
              <Controls onRotateLeft={handleRotate} />
            </ImageContainer>
          </FullSizeDiv>
        </BottomDrawer>
      ),
    [previewImage, isDesktop, rotation, handleRotate, t],
  );

  return {
    onImageClicked: setPreviewImage,
    imagePreviewComponent,
  };
}

export default useImagePreview;
const IconWrapper = styled.div`
  color: ${theme.colors.black};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px;
    width: 20px;
  }
  margin-right: 10px;
  cursor: pointer;
`;

const ModalRow = styled.div`
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid ${theme.colors.iconGrey};
`;

const ImageContainer = styled.div`
  position: relative;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: auto;
`;

const FullSizeDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const ImagePreviewModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    box-shadow: none;
    height: 100vh;
    margin-top: 0;
    padding: 20px;
    background: transparent;
  }
  .ant-modal-body {
    box-shadow: none;
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 20px;
  }
  .ant-modal-footer,
  .ant-modal-close {
    display: none;
  }
`;

const BottomDrawer = styled(Drawer)`
  .ant-drawer-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .ant-drawer-body {
    padding: 0px;
    max-height: 100vh;
    overflow-y: auto;
  }
`;
