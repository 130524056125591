import React from 'react';
import styled, { css } from 'styled-components';

const PageContentContainer = ({
  children,
  style,
  hasTopBar = true,
  backgroundImage = false,
  fullWidth = false,
  hasSideBar = false,
  mobileBreakpoint = 800,
}: {
  style?: React.CSSProperties;
  delimHeight?: number;
  children: React.ReactNode;
  hasTopBar?: boolean;
  backgroundImage?: boolean;
  fullWidth?: boolean;
  hasSideBar?: boolean;
  mobileBreakpoint?: number;
}) => {
  return (
    <ContentContainer
      $fullWidth={fullWidth}
      $backgroundImage={backgroundImage}
      $hasTopBar={hasTopBar}
    >
      <WidthContainer
        $fullWidth={fullWidth}
        style={style}
        $hasSidebar={hasSideBar}
        $mobileBreakpoint={mobileBreakpoint}
      >
        {children}
      </WidthContainer>
    </ContentContainer>
  );
};

const WidthContainer = styled.div<{
  $fullWidth: boolean;
  $hasSidebar: boolean;
  $mobileBreakpoint: number;
}>`
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  height: 100%;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      max-width: 100%;
    `}
  ${({ $hasSidebar, $mobileBreakpoint }) =>
    $hasSidebar &&
    css`
      @media (min-width: ${$mobileBreakpoint}px) {
        padding-left: 260px;
      }
    `}
`;

const ContentContainer = styled.div<{
  $hasTopBar: boolean;
  $backgroundImage?: boolean;
  $fullWidth: boolean;
}>`
  position: relative;
  top: ${({ $hasTopBar }) => ($hasTopBar ? '60px' : '0px')};
  height: ${({ $hasTopBar }) => ($hasTopBar ? 'calc(100% - 60px)' : '100%')};
  width: 100%;
  ${({ $backgroundImage }) =>
    $backgroundImage &&
    css`
      background-image: url('https://snapmentorsignindev.blob.core.windows.net/root/superBackground.png');
      background-repeat: no-repeat;
      background-size: cover;
    `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      padding: 0px 0px;
    `}
`;

export default PageContentContainer;
