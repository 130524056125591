import { signIn, SignInAuthorizationParams } from 'next-auth/react';
import { useCallback } from 'react';

import { useMarketingMetrics } from '../providers/PlausibleAnalyticsProvider';
import { useMetrics } from './useMetrics';

export type SignUpProps = {
  callbackUrl?: string;
  authParams?: SignInAuthorizationParams;
};

function useSignUp() {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();

  const signup = useCallback(
    async (props?: SignUpProps) => {
      // Keep this in mixpanel due to funnel building
      metrics.logEvent('Signup', undefined, true);
      track('Signup');
      const currentUrl = window.location.href;
      const callbackURI = props?.callbackUrl ? props.callbackUrl : currentUrl || '/';
      if (props) {
        await signIn(
          'azure-ad-b2c-signup',
          {
            callbackUrl: `/signup/success?callbackURI=${callbackURI}`,
          },
          props.authParams,
        );
      } else {
        await signIn(
          'azure-ad-b2c-signup',
          {
            callbackUrl: `/signup/success?callbackURI=${callbackURI}`,
          },
          { ui_locales: 'en', accountType: 'teacher' },
        );
      }
    },
    [metrics, track],
  );

  return {
    signUp: signup,
  };
}

export default useSignUp;
