import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Center } from '../Center';

export type ObjectFit = 'cover' | 'contain' | 'scale-down';
type Props = {
  src?: string;
  onClick?: () => void;
  onImageLoaded?: () => void;
  fluid?: boolean;
  style?: React.CSSProperties;
  objectFit?: ObjectFit;
  boxShadow?: string;
};

export const Image = (props: Props) => {
  const { src, boxShadow, onClick, fluid, onImageLoaded, style, objectFit } = props;
  const [currentSrc, setCurrentSrc] = useState<string | undefined>();

  // This ref is to prevent flashing spinner when src is updating
  // like src goes from '/my-picture' => undefined => '/my-newpicture'
  useEffect(() => {
    if (src) {
      setCurrentSrc(src);
    }
  }, [src]);
  if (!currentSrc)
    return (
      <Center style={{ width: '100', height: '100%', padding: '10px' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
      </Center>
    );
  return (
    <ImageContainer onMouseUp={onClick}>
      <ImageWrapper
        style={style}
        onLoad={() => onImageLoaded && onImageLoaded()}
        $fullWidth={fluid}
        $objectFit={objectFit}
        $boxShadow={boxShadow}
        src={currentSrc as string}
      />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default Image;

const ImageWrapper = styled.img<{
  $boxShadow?: string;
  $fullWidth?: boolean;
  $objectFit?: ObjectFit;
}>`
  display: block;
  max-width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '50%')};
  max-height: 200px;
  border-radius: 20px;
  object-fit: ${({ $objectFit }) => ($objectFit ? $objectFit : 'cover')};
  box-shadow: ${({ $boxShadow }) => ($boxShadow ? $boxShadow : 'none')};
`;
